import Swiper, { Navigation, Pagination } from 'swiper';

import { Fancybox } from "@fancyapps/ui";

document.addEventListener('DOMContentLoaded', function () {

	var all_sliders = document.querySelectorAll('.wp-block-propulse-swiper-gallery > .swiper');
	all_sliders.forEach(function (element, index) {

		let swiperId = element.id;

		let params = {
			modules: [Navigation, Pagination],
			spaceBetween: 20,
			//loop: false,
			//effect: 'slide',
			centeredSlides: (element.getAttribute('data-centered-slides') === 'true') ? true : false,
			slidesPerView: 1.1,
			//speed: element.getAttribute('data-speed'),
			freeMode: false,
			navigation: {
				prevEl: document.getElementById(swiperId).querySelector('.swiper-button-prev'),
				nextEl: document.getElementById(swiperId).querySelector('.swiper-button-next'),
			},
			pagination: {
				pagination: true,
                el: '.swiper-pagination',
                paginationClickable: true,
			},
			breakpoints: {
				640: {
					slidesPerView: 3.5,
					spaceBetween: 20
				},
				1024: {
					slidesPerView: element.getAttribute('data-slides-per-view'),
					spaceBetween: 20
				}
			}
		}

		const slider = new Swiper('#' + swiperId, params);
	});

	Fancybox.bind("[data-fancybox]", {});

}, false);